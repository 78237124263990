
$(function(){
    $('.top-slider').slick({
        autoplay:true,
        autoplaySpeed:5000,
        dots: true,
        appendDots: $('.top-slider-dots'),
        dotsClass: 'top-slider-dots',
        arrows: true,
        appendArrows: $('.top-slider-arrows'),
        prevArrow: '<div class="top-slider-arrows-item"><img src="images/top/slider-arrow--prev.png"></div>',
        nextArrow: '<div class="top-slider-arrows-item"><img src="images/top/slider-arrow--next.png"></div>',
    });
    
});
